$(function () {
    const mapId = $('#ymap');

    function init() {

        if (mapId.length > 0) {
            const map = new ymaps.Map('ymap', {
                center: [45.114225574567676,38.98405349999995],
                zoom: 17,
                controls: ['fullscreenControl', 'zoomControl', 'trafficControl'],
                behaviors: ['drag', 'dblClickZoom', 'multiTouch']
            });

            const placemark1 = new ymaps.Placemark([45.114225574567676,38.98405349999995], {
                hintContent: mapId.data('content')
            }, {
                iconLayout: 'default#image',
                iconImageHref: 'dist/img/svg/icon-pin.svg',
                iconImageSize: [53, 70],
                iconImageOffset: [-25, -70],
            });

            map.geoObjects
                .add(placemark1)
        }
    }

    ymaps.ready(init);
})
