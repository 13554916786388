$(function() {

    let days = document.getElementById('days');
    let hours = document.getElementById('hours');
    let minutes = document.getElementById('minutes');
    let seconds = document.getElementById('seconds');

    let dd = document.getElementById('dd');
    let hh = document.getElementById('hh');
    let mm = document.getElementById('mm');
    let ss = document.getElementById('ss');

    let countdown = document.querySelector(".timer__countdown");

    let x = setInterval(function () {

        let dt_start = new Date(),
            dt_end = new Date(),
            distance;

        dt_start = Math.round(dt_start / 1000);
        dt_end.setDate(dt_end.getDate() + 3);
        dt_end.setHours(12);
        dt_end.setMinutes(0);
        dt_end.setSeconds(0);
        dt_end.setMilliseconds(0);
        dt_end = Math.round(dt_end / 1000);

        if (dt_end > dt_start) {
            distance = dt_end - dt_start;
        } else {
            distance = 0;
        }

        // time calculation
        let d = Math.floor(distance / (60 * 60 * 24));
        let h = Math.floor((distance % (60 * 60 * 24)) / ( 60 * 60));
        let m = Math.floor((distance % (60 * 60)) / 60);
        let s = Math.floor(distance % 60);

        // output the result
        days.innerHTML = d + "<br><span>дней</span>";
        hours.innerHTML = h + "<br><span>часов</span>";
        minutes.innerHTML = m + "<br><span>минут</span>";
        seconds.innerHTML = s + "<br><span>секунд</span>";

        // animate stroke
        dd.style.strokeDashoffset = 652 - (652 * d) / 365;
        hh.style.strokeDashoffset = 652 - (652 * h) / 24;
        mm.style.strokeDashoffset = 652 - (652 * m) / 60;
        ss.style.strokeDashoffset = 652 - (652 * s) / 60;
    }, 1000);
})
